import React from 'react'
import PropTypes from 'prop-types'
// import logo from '../images/cigeza_logo_thick.svg'

const Footer = () => (
  <footer>
  </footer>
)


export default Footer
